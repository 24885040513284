.small-1,  .row .small-1  { width: 8.33333%;  }
.small-2,  .row .small-2  { width: 16.66667%; }
.small-3,  .row .small-3  { width: 25%;       }
.small-4,  .row .small-4  { width: 33.33333%; }
.small-5,  .row .small-5  { width: 41.66667%; }
.small-6,  .row .small-6  { width: 50%;       }
.small-7,  .row .small-7  { width: 58.33333%; }
.small-8,  .row .small-8  { width: 66.66667%; }
.small-9,  .row .small-9  { width: 75%;       }
.small-10, .row .small-10 { width: 83.33333%; }
.small-11, .row .small-11 { width: 91.66667%; }
.small-12, .row .small-12 { width: 100%;      }

.medium-1,  .row .medium-1  { width: 8.33333%;  }
.medium-2,  .row .medium-2  { width: 16.66667%; }
.medium-3,  .row .medium-3  { width: 25%;       }
.medium-4,  .row .medium-4  { width: 33.33333%; }
.medium-5,  .row .medium-5  { width: 41.66667%; }
.medium-6,  .row .medium-6  { width: 50%;       }
.medium-7,  .row .medium-7  { width: 58.33333%; }
.medium-8,  .row .medium-8  { width: 66.66667%; }
.medium-9,  .row .medium-9  { width: 75%;       }
.medium-10, .row .medium-10 { width: 83.33333%; }
.medium-11, .row .medium-11 { width: 91.66667%; }
.medium-12, .row .medium-12 { width: 100%;      }

.large-1,  .row .large-1  { width: 8.33333%;  }
.large-2,  .row .large-2  { width: 16.66667%; }
.large-3,  .row .large-3  { width: 25%;       }
.large-4,  .row .large-4  { width: 33.33333%; }
.large-5,  .row .large-5  { width: 41.66667%; }
.large-6,  .row .large-6  { width: 50%;       }
.large-7,  .row .large-7  { width: 58.33333%; }
.large-8,  .row .large-8  { width: 66.66667%; }
.large-9,  .row .large-9  { width: 75%;       }
.large-10, .row .large-10 { width: 83.33333%; }
.large-11, .row .large-11 { width: 91.66667%; }
.large-12, .row .large-12 { width: 100%;      }

.row .small-offset-1  { margin-left: 8.33333%;  }
.row .small-offset-2  { margin-left: 16.66667%; }
.row .small-offset-3  { margin-left: 25%;       }
.row .small-offset-4  { margin-left: 33.33333%; }
.row .small-offset-5  { margin-left: 41.66667%; }
.row .small-offset-6  { margin-left: 50%;       }
.row .small-offset-7  { margin-left: 58.33333%; }
.row .small-offset-8  { margin-left: 66.66667%; }
.row .small-offset-9  { margin-left: 75%;       }
.row .small-offset-10 { margin-left: 83.33333%; }
.row .small-offset-11 { margin-left: 91.66667%; }
.row .small-offset-12 { margin-left: 100%;      }

.row .medium-offset-1  { margin-left: 8.33333%;  }
.row .medium-offset-2  { margin-left: 16.66667%; }
.row .medium-offset-3  { margin-left: 25%;       }
.row .medium-offset-4  { margin-left: 33.33333%; }
.row .medium-offset-5  { margin-left: 41.66667%; }
.row .medium-offset-6  { margin-left: 50%;       }
.row .medium-offset-7  { margin-left: 58.33333%; }
.row .medium-offset-8  { margin-left: 66.66667%; }
.row .medium-offset-9  { margin-left: 75%;       }
.row .medium-offset-10 { margin-left: 83.33333%; }
.row .medium-offset-11 { margin-left: 91.66667%; }
.row .medium-offset-12 { margin-left: 100%;      }

.row .large-offset-1  { margin-left: 8.33333%;  }
.row .large-offset-2  { margin-left: 16.66667%; }
.row .large-offset-3  { margin-left: 25%;       }
.row .large-offset-4  { margin-left: 33.33333%; }
.row .large-offset-5  { margin-left: 41.66667%; }
.row .large-offset-6  { margin-left: 50%;       }
.row .large-offset-7  { margin-left: 58.33333%; }
.row .large-offset-8  { margin-left: 66.66667%; }
.row .large-offset-9  { margin-left: 75%;       }
.row .large-offset-10 { margin-left: 83.33333%; }
.row .large-offset-11 { margin-left: 91.66667%; }
.row .large-offset-12 { margin-left: 100%;      }

.push-1  { position: relative; right: auto; left: 8.33333%;  }
.push-2  { position: relative; right: auto; left: 16.66667%; }
.push-3  { position: relative; right: auto; left: 25%;       }
.push-4  { position: relative; right: auto; left: 33.33333%; }
.push-5  { position: relative; right: auto; left: 41.66667%; }
.push-6  { position: relative; right: auto; left: 50%;       }
.push-7  { position: relative; right: auto; left: 58.33333%; }
.push-8  { position: relative; right: auto; left: 66.66667%; }
.push-9  { position: relative; right: auto; left: 75%;       }
.push-10 { position: relative; right: auto; left: 83.33333%; }
.push-11 { position: relative; right: auto; left: 91.66667%; }
.push-12 { position: relative; right: auto; left: 100%;      }

.pull-1  { position: relative; left: auto; right: 8.33333%;  }
.pull-2  { position: relative; left: auto; right: 16.66667%; }
.pull-3  { position: relative; left: auto; right: 25%;       }
.pull-4  { position: relative; left: auto; right: 33.33333%; }
.pull-5  { position: relative; left: auto; right: 41.66667%; }
.pull-6  { position: relative; left: auto; right: 50%;       }
.pull-7  { position: relative; left: auto; right: 58.33333%; }
.pull-8  { position: relative; left: auto; right: 66.66667%; }
.pull-9  { position: relative; left: auto; right: 75%;       }
.pull-10 { position: relative; left: auto; right: 83.33333%; }
.pull-11 { position: relative; left: auto; right: 91.66667%; }
.pull-12 { position: relative; left: auto; right: 100%;      }

.column.small-centered,
.columns.small-centered,
.column.medium-centered,
.columns.medium-centered,
.column.large-centered,
.columns.large-centered { margin-left: auto; margin-right: auto; float: none !important; }

.column.small-uncentered,
.columns.small-uncentered,
.column.medium-uncentered,
.columns.medium-uncentered,
.column.large-uncentered,
.columns.large-uncentered { margin-left: 0; margin-right: 0; float: left !important; }

.row {
	width: 100%;
	max-width: 1350px;
}