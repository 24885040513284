.lt-ie9 .show-for-medium-down,
.lt-ie9 .show-for-medium,
.lt-ie9 .show-for-small,
.lt-ie9 .show-on-android {
  display: none !important; }
.lt-ie9 .show-for-large-up,
.lt-ie9 .hide-for-medium-down,
.lt-ie9 .hide-on-android {
  display: block !important; }

// solució dràstica per arreglar sprites
@media only screen and (-webkit-min-device-pixel-ratio: 2) and (min-width: 46.3125em), only screen and (min-resolution: 192dpi) and (min-width: 46.3125em) {
	.home-dest:before,
	.home-dest:before,
	.home-dest__text:after,
	.social--fb,
	.social--tw,
	.social--li,
	.social--yt,
	.footer-logos--farmacies,
	.footer-logos--061,
	.footer-logos--112,
	.s fieldset:before,
	.blocks-list__info:before,
	.dl:before,
	.collapsible__title [aria-expanded=false]:before,
	.collapsible__title [aria-expanded=true]:before {
		background-image: url('img/spritesheet.png') !important;
	}
}

.filter label,
.filter button {
  margin-top: 0 !important;
}

.circles-list__img {
	margin-top: 0 !important;
	margin-left: 0 !important;
}